import { randomize } from "./_helpers";

class IntervalManager {
    currentIntervalData = null;
    currentIntervalIndex = -1;


    updateCurrentIntervalData(intervalData) {
        this.currentIntervalData = intervalData
        this.currentIntervalIndex = intervalData.index // perhaps it's not needed to duplicate that
    }


    getCurrentInterval(data) {
        // This function aims to find the current time interval (based on the hour of the day) from a given list of intervals,
        // and return the associated URLs and the index of the interval within the provided list.

        const currentHour = new Date().getHours(); // Get the current hour (0 - 23)

        const currentInterval = data.find((interval, i) => {
            const [start, end] = interval.time.split('-').map(Number); // Convert "12-15" to [12, 15]

            // Adjust for times wrapping midnight, e.g., "23-2"
            if (start > end) {
                return currentHour >= start || currentHour < end
            } else {
                return currentHour >= start && currentHour < end
            }
        })

        // console.log('currentIII', currentInterval)

        if (currentInterval) {
            const index = data.findIndex(interval => interval.time === currentInterval.time)
            currentInterval.index = index
        }

        return currentInterval
        // {time:'11-12', signedURLs: [...]}
        // {time:'11-12', signedURLs: [...], index: 0}
        // {time:'12-21', signedURLs: [...], index: 9}
    }

    getCurrentIntervalRelatedData(currentInterval) {
        if (currentInterval) {
            // console.log('currentII', currentInterval)
            return {
                time: currentInterval.time,
                // urls: currentInterval.signedURLs,
                urls: currentInterval.encodedURLs,
                index: currentInterval.index
            };
        }

        return { urls: [], index: -1 }; // Default return if no matching interval is found
    }

    // returns array of objects
    // for example: [{ time: "8-12", signedURLs: ["1.mp3", "2.mp3", "3.mp3"] }, {...} ]
    getCurrentDaySongsInPlaylist(playlistArray) {
        // THIS function works (getting as an argument) the whole playlist with all the days intervals
        // IT RETURNS the array with intervals for a particular day. The result of interval sets is time-sorted

        // Get the current day
        const currentDate = new Date();
        const currentDay = currentDate.toLocaleString('en-US', { weekday: 'long' });

        // Define an object to store intervals and their respective songs
        const songIntervals = {};
        //console.log('playlistObj is ', playlistObj)

        playlistArray.forEach(song => {
            // Check if the song has an interval for the current day
            const interval = song.fields[currentDay];
            if (interval) {
                // Check if we already have this interval in the songIntervals object
                if (!songIntervals[interval]) {
                    songIntervals[interval] = [];
                }
                // Add the song's signedUrl to the interval array
                // songIntervals[interval].push(song.signedUrl);
                songIntervals[interval].push(song.fields['Full link']);
            }
        });


        // Convert songIntervals object to the desired array format
        const result = [];
        for (const interval in songIntervals) {
            result.push({
                time: interval,
                // signedURLs: randomize(songIntervals[interval]), // Assuming shuffle is a function you've defined elsewhere
                encodedURLs: randomize(songIntervals[interval]) // Assuming shuffle is a function you've defined elsewhere
            });
        }

        // Now, let's sort the intervals
        result.sort((a, b) => {
            const [startA, endA] = a.time.split('-').map(Number);
            const [startB, endB] = b.time.split('-').map(Number);

            // Handle cases where interval wraps around midnight
            if (startA > endA && (startB <= endB || startA < startB)) return 1;
            if (startB > endB && (startA <= endA || startB < startA)) return -1;

            return startA - startB;
        });

        // We get here array of objects which look like this, they are sorted from early to late:
        // { time: "8-12", signedURLs: ["1.mp3", "2.mp3", "3.mp3"] },
        // { time: "12-16", signedURLs: ["4.mp3", "5.mp3", "6.mp3"] },
        // { time: "16-20", signedURLs: ["7.mp3", "8.mp3", "9.mp3"] }
        // console.log('resultlll', result)
        return result;
    }


    hasCurrentInterval(currentDayPlaylist) {
        return !!this.getCurrentInterval(currentDayPlaylist)
    }
    // this method isn't finished
    // findNearestInterval(data) {
    //     const currentHour = new Date().getHours(); // Get the current hour (0 - 23)
    //
    //     const nearestInterval = data.find((interval, i) => {
    //         const [start, end] = interval.time.split('-').map(Number); // Convert "12-15" to [12, 15]
    //
    //         // Adjust for times wrapping midnight, e.g., "23-2"
    //         if (start > end) {
    //             return currentHour >= start || currentHour < end
    //         } else {
    //             return currentHour >= start && currentHour < end
    //         }
    //     })
    //
    //     if (nearestInterval) {
    //         const index = data.findIndex(interval => interval.time === currentInterval.time)
    //         currentInterval.index = index
    //     }
    //
    //     return nearestInterval
    //     // {time:'11-12', signedURLs: [...]}
    //     // {time:'11-12', signedURLs: [...], index: 0}
    //     // {time:'12-21', signedURLs: [...], index: 9}
    // }
}

export const intervalManager = new IntervalManager()