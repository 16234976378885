import {sendLikeDislike, sendSongStats, fetchPlaylist, setPlayerTitle} from './_helpers';
import {intervalManager} from "./_intervalManager";
import {initializePlayerHTMLControls} from "./controls";
import {loadTrack} from "./loadTrack";
import {playerState} from "./_playerState";
import {likeDislikeService} from "./likeDislikeService";


// ...
// let nextTrackBlobSize = null
let nextTrackDownloadSpeed = null
let nextTrackDownloadTime = null

export class Player {
  currentTrackIndex = 0;
  nextTrackIndex = 1;
  // track urls are used as a lightweight version of id
  currentTrackUrl = null;
  nextTrackUrl = null;
  tracks = null;
  nextBlobURL = null;
  currentBlobURL = null;
  currentPlaylistInitialData = null
  currentDayPlaylist = null;
  currentPlaylistTableId = null;
  currentPlaylistTableName = null;
  allButtons = document.querySelectorAll('button');
  audioPlayer = document.getElementById('audioPlayer');
  baseId = null;
  availablePlaylists = null;

  constructor() {
    if (!this.audioPlayer) {
      throw Error('Error: audioPlayer html element must be set for player to initialize')
    }

  }

  async initializePlayer(availablePlaylists, baseId) {
    this.availablePlaylists = availablePlaylists
    this.baseId = baseId

    // Запрашиваем первый плейлист
    const firstPlaylist = availablePlaylists[0]
    // обновляем все данные о плейлисте
    await this.setPlaylistData({ newPlaylist: firstPlaylist })


    try {
      await this.initializeFirstTwoTracksOfAPlaylist({
        firstTrackLoaded: () => {
          initializePlayerHTMLControls(this); // )
          document.body.classList.add('first-track-loaded');
        }
      })
    } catch (error) {

      console.error(`can't load first 2 tracks`)
    }

    // this.audioPlayer.addEventListener('ended', async () => this.onTrackEnd())
    // it's made to avoid complexity when reinitializing a player
    // so it's not needed to manually unsubscribe from addEventListener (it's not needed to call removeEventListener)
    this.audioPlayer.onended = async () => this.onTrackEnd()
  }


  async setPlaylistData({ newPlaylist }) {
    // show user friendly message
    setPlayerTitle('loading playlist...')

    this.currentPlaylistTableId = newPlaylist.tableId
    this.currentPlaylistTableName = newPlaylist.playlistName
    this.currentPlaylistInitialData = await fetchPlaylist(this.baseId, this.currentPlaylistTableId)
    this.currentDayPlaylist = intervalManager.getCurrentDaySongsInPlaylist(this.currentPlaylistInitialData);

    const currentInterval = intervalManager.getCurrentInterval(this.currentDayPlaylist)

    const currentIntervalData = intervalManager.getCurrentIntervalRelatedData(currentInterval)
    intervalManager.updateCurrentIntervalData(currentIntervalData)

    this.tracks = intervalManager.currentIntervalData.urls;
  }

  async initializeFirstTwoTracksOfAPlaylist({ firstTrackLoaded }) {
    // document.querySelector('#current-playlist').innerHTML =
    setPlayerTitle('loading first track...')

    // reset
    this.currentTrackIndex = 0;
    this.nextTrackIndex = 1;

    const retryFirstTrack = () => {
      return loadTrack({ tracks: this.tracks, trackIndex: this.currentTrackIndex})
      .catch(() => {

        this.currentTrackIndex++
        return retryFirstTrack()
      })
    }

    const retrySecondTrack = () => {
      return loadTrack({ tracks: this.tracks, trackIndex: this.nextTrackIndex })
      .catch(() => {

        this.nextTrackIndex++
        return retrySecondTrack()
      })
    }

    retryFirstTrack()
    .then(blobURL => {
      this.currentBlobURL = blobURL;
      this.currentTrackUrl = this.tracks[this.currentTrackIndex]

      this.audioPlayer.src = this.currentBlobURL;

      firstTrackLoaded()

      // show the name of a playlist to an user

      setPlayerTitle(this.currentPlaylistTableName)

      console.log('first blob should be ready');
      return retrySecondTrack();
    }).then(blobURL => {
      this.nextBlobURL = blobURL;
      this.nextTrackUrl = this.tracks[this.nextTrackIndex]

      document.getElementById('skip-button').disabled = false
      console.log('first two tracks of a playlist are initialized')
    }).catch(error => {
      console.error('Error setting the source for the audio player:', error);
    });
  }

  async onTrackEnd() {
    // if playlist is changing, don't load next song of current playlist
    const playlistChange = playerState.playlistShouldChange
    // reset this global value
    playerState.playlistShouldChange = false

    document.getElementById('skip-button').disabled = true


    const currentTrackUrl = this.currentTrackUrl

    const currentTrackInitialData = this.currentPlaylistInitialData.find(trackData => trackData.fields['Full link'] === currentTrackUrl)

    console.log('%ccurrentTrackIndex', 'color: green', this.currentTrackIndex)
    console.log('currentTrackUrl', currentTrackUrl)

    const currentTrackId = currentTrackInitialData.id


    // this object will be sent to server
    const data = {
      baseId: this.baseId,
      tableId: this.currentPlaylistTableId,
      recordId: currentTrackId,
      currentIndex: this.currentTrackIndex,
      // first and second tracks of a playlist are without speed and time calculations, so use empty strings instead...
      downloadingSpeed: nextTrackDownloadSpeed ? nextTrackDownloadSpeed.toFixed(1) : '',
      downloadingTime: nextTrackDownloadTime ? nextTrackDownloadTime.toFixed(1) : ''
    }

    // reset this global variable
    nextTrackDownloadSpeed = null
    nextTrackDownloadTime = null

    let trackWasDeleted;
    if (likeDislikeService.likeDislikeStatus.scheduled) {
      const newStatus = likeDislikeService.likeDislikeStatus.newStatus
      // set 'Like' or 'Dislike' that will be sent to server
      data.newStatus = newStatus

      if (newStatus === 'Dislike') {
        // delete track from current playlist locally
        this.tracks = this.tracks.filter(track => track !== currentTrackUrl)
        trackWasDeleted = true
      }

      setTimeout(() => {
        sendLikeDislike(data)
      }, 1200) // wait too overcome the 5 request per second limit
      likeDislikeService.resetLikeDislikeScheduledValues()
    }

    const stats = data
    stats.skipped = playerState.skipped
    stats.playlistName = this.currentPlaylistTableName
    stats.timestamp = new Date().toLocaleString('ru-RU')

    setTimeout(() => {
      sendSongStats(stats)
      // wait 3 seconds for hopefully pass airtable 5-requeste-at-once limit
    }, 3000)

    // reset skipped to initial value
    playerState.skipped = false

    console.log('audioPlayer ended')
    // if track is ended due to playlist change, don't load next track
    if (!playlistChange) {
      await this.playAndLoadNextTrack({trackWasDeleted})
    }
  }

  async playAndLoadNextTrack({ trackWasDeleted }) {

    console.log('tracks[currentTrackIndex] and encodedURL is ' + this.tracks[this.currentTrackIndex])

    // If there is a next track
    if (this.nextBlobURL) {
      // Revoke the blob URL of the track that just finished playing
      if (this.currentBlobURL) {
        URL.revokeObjectURL(this.currentBlobURL);
      }

      this.currentBlobURL = this.nextBlobURL;
      this.nextBlobURL = null;
      this.currentTrackUrl = this.nextTrackUrl
      this.nextTrackUrl = null;
      this.audioPlayer.src = this.currentBlobURL;
      this.audioPlayer.play();

      if (!trackWasDeleted) {
        // update indexes only if previous track wasnt' deleted
        // if track was deleted indexes remain the same
        this.currentTrackIndex = this.nextTrackIndex;
        this.nextTrackIndex++;
      }

      // it's a temporary local variable
      const currentInterval = intervalManager.getCurrentInterval(this.currentDayPlaylist);
      const currentIntervalData = intervalManager.getCurrentIntervalRelatedData(currentInterval)

      if (currentIntervalData.index === -1) {
        // switched to no interval time
        playerState.playlistEnded = true

        console.warn('playlist has ended')
        console.warn('if user presses play and there is a new interval already, a player should reinitialize')

        return
      }


      const retry = () => {
        console.log('retry track index:', this.nextTrackIndex)

        if (intervalManager.currentIntervalIndex !== currentIntervalData.index) {
          console.log('switched playlist interval')
          console.log('current active interval is', currentIntervalData.time)

          intervalManager.updateCurrentIntervalData(currentIntervalData)

          this.tracks = intervalManager.currentIntervalData.urls;
          this.nextTrackIndex = 0; // Start from the first track in the new interval
        } else if (this.nextTrackIndex >= this.tracks.length) {
          // If we're beyond the end of the current tracks, loop back to the start
          this.nextTrackIndex = 0;
        }

        const downloadingTimeStart = new Date().getTime()
        let downLoadingTimeEnd;

        return loadTrack({ tracks: this.tracks, trackIndex: this.nextTrackIndex, returnOnlyBlob: true })
            .then((blob) => {
              // on success, calculate how much time it took to download this track
              downLoadingTimeEnd = new Date().getTime()
              const downloadTimeInSeconds = (downLoadingTimeEnd - downloadingTimeStart) / 1000

              if (blob.size > 0) {
                const blobSizeMb = blob.size / 1024 / 1024
                nextTrackDownloadTime = downloadTimeInSeconds
                nextTrackDownloadSpeed = blobSizeMb/downloadTimeInSeconds

                console.log('Successfully fetched and have content in blob.');
                return URL.createObjectURL(blob);
              } else {
                console.warn('Fetch was successful but blob is empty.');

                return null
              }
            })
          .catch(() => {
            this.nextTrackIndex++
            return retry()
          })
      }

      retry()
        .then(blobURL => {
          this.nextBlobURL = blobURL;
          this.nextTrackUrl = this.tracks[this.nextTrackIndex]

          console.log('track loaded (with or without retry)')
          document.getElementById('skip-button').disabled = false
        });
    }
  }

}
