
class PlayerState {
    skipped = false
    playlistShouldChange = false
    globalRepeatId = null
    playlistEnded = false

    resetRepeatId() {
        this.globalRepeatId = null
    }
}

export const playerState = new PlayerState()